import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Anchor, Heading, Paragraph } from '@lux/components-gomo';
import styled from 'styled-components';
import withLayout from '../components/withLayout';
import { navigate } from 'gatsby';
import { remCalc } from '@lux/helpers';

import {
  PRODUCT_NAME,
  HTTP_404,
  RETURN_TO_HOME_PAGE
} from '../constants/page_content.json';
import { NUMBER_SELECTION_PAGE } from '../constants/links.json';

const Container = styled.div`
  text-align: center;
`;

const Section = styled.div`
  margin: ${remCalc(20)} 0;
`;

const Failure = () => {
  return (
    <Fragment>
      <Helmet defaultTitle={PRODUCT_NAME} titleTemplate="%s">
        <title>{HTTP_404.TITLE}</title>
      </Helmet>
      <Container>
        <Heading level={2}>{HTTP_404.HEADING.toUpperCase()}</Heading>
        <Section>
          <Paragraph>{HTTP_404.DESCRIPTION}</Paragraph>
        </Section>
        <Section>
          <Anchor onClick={() => navigate(NUMBER_SELECTION_PAGE.RELATIVE)}>
            {RETURN_TO_HOME_PAGE}
          </Anchor>
        </Section>
      </Container>
    </Fragment>
  );
};

export default withLayout(Failure);
